import { FaTelegramPlane } from 'react-icons/fa';
import NavBar from '../Navbar';
import { RiTwitterXFill } from 'react-icons/ri';

const Hero = () => {
    return (
        <div className="relative overflow-x-hidden h-[calc(100vh-115px)] flex flex-col">
            <NavBar />
            <main className="container mx-auto px-4 flex-1 flex items-center justify-center">
                {/* Main content container */}
                <div className="flex flex-row md:flex-col items-center justify-center gap-6">
                    {/* Right side - Image */}
                    <div className="w-1/2 md:w-full flex justify-center items-center">
                        <img
                            src="/assets/images/HighResPeezy_02.png"
                            alt="Peezy"
                            className="w-[150%] xl:w-[160%] lg:w-[140%] md:w-[120%] 
                                max-w-none object-contain bounce-animation -mt-12 md:mt-0"
                        />
                    </div>
                    {/* Left side - Content */}
                    <div className="w-1/2 md:w-full flex flex-col items-center space-y-4">
                        {/* Logo/Title */}
                        <h1 className="font-Genty text-white text-center
                            text-11xl xl:text-9xl xl:text-9xl md:hidden -mt-8">
                            peezy
                        </h1>

                        {/* Buy Button */}
                        <div className="w-full flex justify-center">
                            <a 
                                href="https://app.pulsex.com/swap?outputCurrency=0x698cb3223d8eb1a3d9908e304775118df4f81933"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center px-6 py-3
                                    bg-black hover:bg-white/20 backdrop-blur-md
                                    border border-white/10 text-white rounded-full
                                    transition-all duration-300 w-auto md:w-50% max-w-md"
                            >
                                <img
                                    src="/assets/images/PulseX_X.png"
                                    className="w-8 h-8 brightness-0 invert mr-2"
                                    alt="PulseChain"
                                />
                                <span className="font-MonumentExtended text-base md:text-sm">
                                    BUY Peezy
                                </span>
                            </a>
                        </div>

                        {/* Social Links */}
                        <div className="flex flex-wrap justify-center gap-4">
                            {/* Twitter */}
                            <a 
                                href="https://x.com/peezycoinpls"
                                className="social-link"
                            >
                                <RiTwitterXFill className="w-6 h-6" />
                            </a>

                            {/* Telegram */}
                            <a 
                                href="https://t.me/peezycoinpls"
                                className="social-link"
                            >
                                <FaTelegramPlane className="w-6 h-6" />
                            </a>

                            {/* PulseX */}
                            <a 
                                href="https://app.pulsex.com/swap?outputCurrency=0x698cb3223d8eb1a3d9908e304775118df4f81933"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-link"
                            >
                                <img
                                    src="/assets/images/PulseX_X.png"
                                    alt="PulseX"
                                    className="w-7 h-7 brightness-0 invert"
                                />
                            </a>

                            {/* Dexscreener */}
                            <a 
                                href="https://dexscreener.com/pulsechain/0x698cb3223d8eb1a3d9908e304775118df4f81933"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-link"
                            >
                                <img
                                    src="/assets/images/dexscreener(2).png"
                                    alt="Dexscreener"
                                    className="w-12 h-12"
                                />
                            </a>
                        </div>
                    </div>


                </div>
            </main>

            {/* Add base styles for social links */}
            <style jsx>{`
                .social-link {
                    @apply flex items-center justify-center rounded-full w-14 h-14
                        bg-gradient-to-r from-white/10 to-white/5
                        hover:from-white/20 hover:to-white/10
                        backdrop-blur-md border border-white/10
                        text-white hover:text-white transition-all duration-300;
                }
            `}</style>
        </div>
    );
}

export default Hero;